'use strict'

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Link, useParams } from 'react-router-dom'
import { render } from 'react-dom'
import { Col, Row, Image } from 'react-bootstrap'
import ReactGA from 'react-ga4'
import { Masonry } from 'react-masonry-responsive'


class ScrollToTopOnMount extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return null;
    }
}


const createMarkup = (data) => { return { __html: data }; }

const LinkList = (props) => {
    var links = null;
    if (!props.links || props.links.length == 0) {
        links = <li> {props.emptytext} </li>;
    } else {
        links = props.links.map((link) => {
            if(link.url) {
                return <li key={link.title}><a href={link.url} target="_blank"><i className="fa fa-external-link-square" /> {link.title} </a></li>
            } else {
                return <li key={link.title}><i className="fa fa-external-link-square" /> {link.title} </li>
            }
        });
    }
    return (<>
            <h2 className="mt0"><strong>{props.title}</strong></h2>
            <ul className="partner-link">
                {links}
            </ul></>);
}

const LinkTree = () => {
    const [links, setLinks] = useState([]);
    useEffect(() => import('../../testcases.js').then(validationcases => {
        const links = validationcases.default.map((vcase, index) => {
            return { url: "/validation/" + vcase.slug, name: vcase.title };
        })
        setLinks(links)
    }), [])
    return links.map((link, index) => {
        return <li key={link.url}><a href={link.url}>{link.name}</a></li>
    });
}

const ValidationImage = (props) => {
    return <Col md={props.md}>
        <div className="image-box-single mb20">
            <div className="overlay-container">
                <img src={props.img} />
                <a href={props.img} className="popup-img overlay">
                    <i className="fa fa-search-plus" />
                </a>
            </div>
            {props.imgtxt ? <div className="image-box-body" dangerouslySetInnerHTML={createMarkup(props.imgtxt)}></div> : null}
        </div>
    </Col>
}

const ImageGrid = (props) => {
    const images = props.images.map((image, index) => {
        return <ValidationImage {...image} key={index} />;
    });
    return <Row className="mt40">
        {images}
    </Row>;
}

const TestcaseSection = (props) => {
    return (<Row>
        <Col md={12}>
            <h2 className="mt0"><strong>{props.title}</strong></h2>
            <div dangerouslySetInnerHTML={createMarkup(props.body)} />
            {props.images ? <ImageGrid images={props.images} /> : null}
            <hr />
        </Col>
    </Row>);
}

function logCaseDownload(casename) {
    ReactGA.event({
        category: 'Download',
        action: 'Downloaded case ' + casename,
    });
}

const DownloadLink = ({ slug }) => {
    return (<a onClick={() => logCaseDownload(slug)} href={"/" + VERSION + "/" + slug + ".tgz"}>
        <span className="btn btn-default button" aria-hidden="true"><i className="fa fa-download pr10" aria-hidden="true"></i>Download</span>
    </a>)
}

const TestcasePage = (props) => {
    useEffect(() => {
        MathJax.Hub.Typeset();
    });

    const sections = props.sections.map((section, index) => {
        return <TestcaseSection key={index} {...section} />;
    });
    let downloadlink = null
    if (props.hasDownload) {
        downloadlink = <div className="tr-action validation">
            <DownloadLink slug={props.slug} />
        </div>
    }
    let refs = null
    if (props.references) {
        refs =  <LinkList title="References" links={props.references} emptytext="Reference links" />
    }
    return <div className="container">
        <Row><Col md={12} className="main">
            <h1 className="page-title mt40">{props.title}</h1>
                 {downloadlink}
            <hr />
                 {sections}
                 {refs}
        </Col></Row>
      </div>;
}

const TestcaseItem = (props) => {
    const tags = props.tags.map((tag, index) => {
        return <li key={index}><a onClick={() => props.filterChange(tag)} >{tag}</a></li>
    });

    const classes = props.tags.join(' ');
    return <div className={classes} style={{ width: '300px' }}>
        <div className="image-box bordered-img">
            <div className="overlay-container">
                <Image responsive="true" src={props.image} style={{ height: '180px' }} />
                <div className="overlay">
                    <div className="overlay-links">
                        <Link to={"/validation/" + props.slug} ><i className="fa fa-chevron-right" />
                        </Link>
                    </div>
                </div>
            </div>
            <div className="image-box-body">
                <h3 className="title"><Link to={"/validation/" + props.slug}>{props.title}</Link></h3>
                <p> {props.shorttext} </p>
                <Link to={"/validation/" + props.slug} className="btn btn-default">More detail</Link>
                <div className="tags">
                    <div className="filters">
                        <ul className="nav nav-pills">
                            {tags}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const TestcaseGrid = ({ children }) => {
    const masonryChildren = children.map(child => ({ key: child.key, node: child }))
    return <Masonry containerWidth={1280} gap={20} items={masonryChildren} minColumnWidth={300} />
}

const TestcaseFilters = (props) => {
    return <div className="filters">
        <ul className="nav nav-pills">
            {props.filters.map((filter, index) => {
                if (props.filter == filter) {
                    var classes = "active";
                } else {
                    var classes = null;
                }
                return <li key={index} className={classes}><a onClick={() => props.filterChange(filter)} ><i className="fa fa-tags pr5" aria-hidden="true" />{filter}</a></li>
            })}
        </ul>
    </div>
}

const matchTag = (filter, tags) => {
    let matches = false
    tags.forEach((val) => {
        if (filter == val) {
            matches = true
        }
    })
    return matches
}

const EmptyIntro = props => <div className="page-intro mb20">
    <div className="container">
        <Row><Col md={12} /></Row>
    </div></div>

class TestcaseView extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = { filter: "All", validationcases: [] };
        import('../../testcases.js').then(
            validationcases => { this.setState({ validationcases: validationcases.default }) }
        )
    }

    setFilter(filter) {
        this.setState({ filter: filter });
    }

    render() {
        const filter = this.state.filter
        const testcase_candidates = this.state.validationcases.map((testcase, index) => {
            if (filter == "All" || matchTag(filter, testcase.tags)) {
                return <TestcaseItem key={testcase.slug} filterChange={(filter) => this.setFilter(filter)} {...testcase} />
            } else {
                return null
            }
        });
        const testcases = testcase_candidates.filter(element => element !== null)
        return <div>
            <ScrollToTopOnMount />
            <EmptyIntro />
            <div className="container">
                <Row>
                    <Col md={12}>
                        <h1 className="page-title">Validation</h1>
                        <p className="lead">Continuous validation and verification is at the heart of our software development process.  These test cases are run every time we update zCFD and are also a useful reference for setting up and running your own jobs. You can browse the library below, or click on the tabs to look for specific features. </p>
                        <hr />
                        <TestcaseFilters filter={filter} filterChange={(filter) => this.setFilter(filter)} filters={
                            ["All",
                                "Implicit",
                                "Explicit",
                                "Finite Volume",
                                "High Order"
                            ]
                        } />
                        <TestcaseGrid>{testcases}</TestcaseGrid>
                    </Col>
                </Row>
            </div>
        </div>
    }
}

function matches_slug(slug, element) {
    return slug == element.slug;
}

const TestcasePageView = ({ props }) => {

    //        const match = useMatch("/validation/:slug");
    //        console.log(match)
    const [tcase, settcase] = useState(null)
    const { slug } = useParams()
    import('../../testcases.js').then(validationcases => {
        const tcase = validationcases.default.find((element) => { return matches_slug(slug, element) })
        settcase(tcase)
    })
    if (tcase) {
        return (
            <div>
                <ScrollToTopOnMount />
                <TestCaseBreadCrumbs name={tcase.title} />
                <TestcasePage {...tcase} />
            </div>)
    } else {
        return null
    }
}

const TestCaseBreadCrumbs = ({ name, children }) => {
    return <div>
        <div className="page-intro">
            <div className="container">
                <Row>
                    <Col md={12}>
                        <ol className="breadcrumb">
                            <li><i className="fa fa-home pr10" /><Link to="/validation">Validation</Link></li>
                            <li>{name}</li>
                        </ol>
                    </Col>
                </Row>
            </div>
        </div>
        {children}
    </div>
}

function logPageView() {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
}

function logTabClick(tabname) {
    ReactGA.event({
        category: 'Navigation',
        action: 'Clicked Tab ' + tabname,
    });
}

const mainelement = document.getElementById('main')
const vlinks = document.getElementById('vlinks')
ReactGA.initialize('G-WS9YF9V2D0');
//Raven.config('https://b9849bf04e5c4422a7382ff3509c09ac@o445752.ingest.sentry.io/5430625').install()

document.addEventListener('DOMContentLoaded', () => {
    import('cookieconsent').then(CookieConsent => {
        window.cookieconsent.initialise({
            "theme": "classic",
        });
    });
})

if (mainelement) {
    render(<BrowserRouter onUpdate={logPageView}>
        <Routes>
            <Route exact path="/validation/index.html"
                element={<TestcaseView />} />
            <Route exact path="/validation"
                element={<TestcaseView />} />
            <Route exact path="/validation/" element={<TestcaseView />} />
            <Route path="/validation/:slug" element={
                <TestcasePageView />
            } />
        </Routes>
    </BrowserRouter>,
        mainelement
    )
}

if (vlinks) {
    render(<LinkTree />, vlinks)
}
